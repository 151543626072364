export const sgContentIconTiles = [
    {
        icon: 'medical-treatment',
        title: 'Seek medical treatment',
        blurb: `<p>Even if your injury/illness appears to be minor, if you are involved in an accident at work it is important to visit a doctor to start an early record of symptoms, their cause and impact on your life.</p>
        <p>This appointment will provide the historical background which will be critical to the success of your WorkCover claim.</p>`,
    },
    {
        icon: 'notify-employer',
        title: 'Notify your employer',
        blurb: `<p>You need to notify your employer within 30 days of becoming aware you have a work injury/illness.</p>
        <p>Further, you only have six years from your date of injury/illness to sue your employer if they were at fault. This date may be extended in some circumstances, such as if you have only recently become aware of the seriousness of your injury/illness.</p>
        <p>It is important that you do not sign anything without obtaining legal advice first.</p>`,
    },
    {
        icon: 'claim',
        title: 'Lodge a WorkCover claim',
        blurb: `<p>WorkCover claim forms are available at the post office, one of our offices or you can request one by calling WorkSafe on 1800 136 089.</p>
        <p>If you have medical expenses or need to take time off work, you will need to complete a WorkCover claim form and provide it to your employer.  A sample of a completed form is available here.</p>
        <p>If you are claiming for time off work, you also need to have a "certificate of capacity" from your doctor.</p>
        <p>If you need assistance completing your WorkCover claim form, please call one of our lawyers on (03) 9321 9988.</p>`,
    },
    {
        icon: 'contact',
        title: 'Contact us',
        blurb: `<p>Even if you don’t think your injury is serious, we recommend discussing your injury/illness with a lawyer while the details are fresh in your mind.</p>
        <p>Your time is important to us, so when you call (03) 9321 9988 during business hours Monday - Friday, your call will be answered by a lawyer. We will be able to advise you on your potential rights and entitlements, and offer a free face-to-face meeting at an office closest to you.</p>
        <p>Legal services are provided on a No Win, No Fee basis.</p>`,
    },
    {
        icon: 'on-the-road',
        title: 'One the Road',
        blurb: `<p>If you or a family member is injured at work, it is important to get the right advice about a Workers Compensation claim.</p>`,
    },
    {
        icon: 'medical-professional',
        title: 'Medical Professional',
        blurb: `<p>If you or a family member is injured at work, it is important to get the right advice about a Workers Compensation claim.</p>`,
    },
];

export default sgContentIconTiles;
