import React, { useEffect } from "react";
import Prism from "prismjs";
import SgLayout from "~layouts/sg-layout";
import CodeBlock from '~2-components/SgCodeBlock/SgCodeBlock';
import ContentIconTile from '~2-components/ContentIconTile/ContentIconTile';
import SliderContainer from '~2-components/Slider/SliderContainer';
import sgContentIconTiles from '~data/sgContentIconTiles';

const sliderContainerEg = `import SliderContainer from '~2-components/Slider/SliderContainer';

<SliderContainer
    settings = {} //Optional, override default settings.
>
    {slider items}
</Slider>

// Any Slick Slider setting is valid.s
defaultSettings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true
}
`;

const SgSliderContainer = () => {
    useEffect(() => {
        // call the highlightAll() function to style our code blocks
        Prism.highlightAll();
    });

    return (
        <SgLayout>
            <section className="sg-content">
                <div className="container">
                    <h1>Slider Container</h1>
                    <h3>How to use</h3>
                    <CodeBlock
                        code={sliderContainerEg}
                    />

                    <h3>Example</h3>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <SliderContainer>
                        {sgContentIconTiles.map(item => {
                            return (
                                <ContentIconTile
                                    key={item.id}
                                    icon={item.icon}
                                    title={item.title}
                                    blurb={item.blurb}
                                />
                            );
                        })}
                    </SliderContainer>
                </div>
            </section>
        </SgLayout>
    );
};

export default SgSliderContainer;
